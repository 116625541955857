<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { required,sameAs } from "vuelidate/lib/validators";
import {changePassword} from '@/apis/user'
export default {
  page: {
    title: "修改密码",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "修改密码",
      user: {
        newpassword: "",
        renewpassword: "",
        password: "",
      },
      submitted: false,
      updateError: null,
      tryingToRegister: false,
      isUpdateError: false,
      updateSuccess: false,      
    };
  },
  validations: {
    user: {
      password: {
        required,
      },
      newpassword: {
        required,
      },
      renewpassword: {
        required,
        sameAsPassword: sameAs("newpassword") 
      },
    },
  },
  methods: {
    tryToChangePassword() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        changePassword(this.user).then(()=>{
            this.updateSuccess = true;
        }).catch((res)=>{
            this.updateSuccess = false;
            if(res.message=="ERR_PWD_ERR"){
                this.updateError = "原始密码错误"
            }else{
                this.updateError = res.message
            }            
            this.isUpdateError=true;
        })
      }
    },
  },  
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xxl-12 col-lg-12">
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title mb-4">{{title}}</h5>
                    <div class="row">
                        <b-alert
                        v-model="updateSuccess"
                        class="mt-3"
                        variant="success"
                        dismissible
                        >修改成功</b-alert>

                        <b-alert
                        v-model="isUpdateError"
                        class="mt-3"
                        variant="danger"
                        dismissible
                        >{{ updateError }}</b-alert>          
                        <b-form @submit.prevent="tryToChangePassword" class="form-horizontal mt-4" >
                            <b-form-group
                                id="password-group"
                                label="当前密码"
                                label-for="password"
                                label-class="form-label"
                                class="mb-3"
                            >
                                <b-form-input
                                id="password"
                                v-model="user.password"
                                type="password"
                                :class="{
                                    'is-invalid': submitted && $v.user.password.$error,
                                }"
                                ></b-form-input>
                                <div
                                v-if="submitted && !$v.user.password.required"
                                class="invalid-feedback"
                                >
                                请输入当前密码
                                </div>
                            </b-form-group>
                            <b-form-group
                                id="newpassword-group"
                                label="新密码"
                                label-for="newpassword"
                                label-class="form-label"
                                class="mb-3"
                            >
                                <b-form-input
                                id="newpassword"
                                v-model="user.newpassword"
                                type="password"
                                :class="{
                                    'is-invalid': submitted && $v.user.newpassword.$error,
                                }"
                                ></b-form-input>
                                <div
                                v-if="submitted && !$v.user.newpassword.required"
                                class="invalid-feedback"
                                >
                                请输入新密码
                                </div>
                            </b-form-group>    
                            <b-form-group
                                id="renewpassword-group"
                                label="确认密码"
                                label-for="renewpassword"
                                label-class="form-label"
                                class="mb-3"
                            >
                                <b-form-input
                                id="renewpassword"
                                v-model="user.renewpassword"
                                type="password"
                                :class="{
                                    'is-invalid': submitted && $v.user.renewpassword.$error,
                                }"
                                ></b-form-input>
                                <div
                                v-if="submitted && !$v.user.renewpassword.required"
                                class="invalid-feedback"
                                >
                                请输入确认密码
                                </div>
                                <div
                                v-if="submitted && !$v.user.renewpassword.sameAsPassword"
                                class="invalid-feedback"
                                >
                                密码输入不一致
                                </div>
                            </b-form-group>                                
                            <div class="form-group mb-0 text-center">
                                <div class="col-12 text-end">
                                <b-button type="submit" variant="primary" class="w-md"
                                    >提交</b-button
                                >
                                </div>
                            </div>
                        </b-form>                                      
                    </div>
              </div>
              <!-- end card body -->
          </div>
          <!-- end card -->
      </div>
      <!-- end col -->
  </div>
  </Layout>
</template>
<style lang="scss">
.user-profile-img{
    position: relative;
    .overlay-content{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba($primary, 0.8);
        display: flex;
        height: 100%;
        color: rgba($white, 0.6);
        flex-direction: column;
    }
    .profile-img{
        width: 100%;
        height: 250px;
        object-fit: cover;
  
        @media (max-width:991px) {
            height: 160px;
        }
    }
  }

</style>